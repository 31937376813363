import React,  { Component } from "react"
import {graphql, Link} from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import Img from "gatsby-image";
import Layout from "../components/layout"
import SEO from "../components/seo";




class SingleTag extends Component {


    render() {
        const data = this.props.data


        const resulta = data.allWordpressPost;
        const resultb = data.allWordpressWpProjects;



        return (
            <Layout>
                <SEO
                    title={this.props.pageContext.name}
                    description={this.props.pageContext.name}

                />

                <div>Tag: <strong>{this.props.pageContext.name}</strong></div>

                    {resulta.edges.map(({ node }) => (
                    <div key={node.id}>
                        <h2 className="click-header" >
                            <Link to={`/blog/${node.slug}`}>
                                {node.title}
                            </Link>
                        </h2>



                        <Img
                            alt={node.featured_media.alt_text}
                            fluid={node.featured_media.localFile.childImageSharp.fluid}

                        />
                        <div className="metadata">
                            {node.formatted_date}:

                        </div>

                        <div>


                            {parse(node.excerpt)}
                        </div>
                        <Link
                            to={`/blog/${node.slug}`}
                            aria-label={`Continue reading ${node.title}`}
                        >
                            <button  className="StyledLinkButton">
                                Continue reading
                            </button>
                        </Link>
                    </div>
                ))}
                {resultb.edges.map(({ node }) => (
                <div key={node.id}>
                    <h2 className="click-header" >
                        <Link to={`/projects/${node.slug}`}>
                            {node.title}
                        </Link>
                    </h2>



                    <Img
                        alt={node.featured_media.alt_text}
                        fluid={node.featured_media.localFile.childImageSharp.fluid}

                    />
                    <div className="metadata">
                        {node.formatted_date}:

                    </div>

                    <div>


                        {parse(node.excerpt)}
                    </div>
                    <Link
                        to={`/projects/${node.slug}`}
                        aria-label={`Continue reading ${node.title}`}
                    >
                        <button  className="StyledLinkButton">
                            Continue reading
                        </button>
                    </Link>
                </div>
                ))}

            </Layout>
        )
    }
}

SingleTag.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default SingleTag

export const tagQuery = graphql`
  query($id: String!) {
    
     allWordpressPost(filter: {tags: {elemMatch: {id: {eq: $id}}}}) {
      edges {
        node {
        id
          title
          excerpt
          slug
          date
          type   
  
           featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        }
      }
  }
   allWordpressWpProjects(filter: {tags: {elemMatch: {id: {eq: $id}}}}) {
      edges {
        node {
        id
          title
          excerpt
          slug
          date   
            type
           featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        }
      }
  }
  
  
    site {
      siteMetadata {
        title
      }
    }
  }
`



